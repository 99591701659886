import {
  AnswerSetBuilder,
  QuestionnaireHelper,
  DocumentDrafterQuestionnaire,
  RequestForOfferQuestionnaireProvider,
  Tender,
  ValueQuestionBase,
  useBackend,
} from '@dims/components';
import RequestQuestionnaireValidator from './RequestQuestionnaireValidator';
import { TenderData0214 } from '@/models';

class RequestQuestionnaireProvider
implements RequestForOfferQuestionnaireProvider {
  backend = useBackend();
  constructor(readonly tender: Tender) {}

  activatorLabel = 'Leveringskontrakt og Opfordring';
  hasPreviewableCsr = false;

  // TODO: Change to key, when keys are set
  category1SettlementTypeMap: Record<string, string> = {
    'c1e49488-3f4d-4978-a237-bcc7b5dc299d':
        '1ba7eb8d-063a-4d22-9894-3cb3f77ed3f8', // Timekøb
    'ca7c5958-e8cc-4ecf-90b2-58a0a39d2693':
        '185ceb2f-fb9a-44b1-ba52-3fd63c861302', // 'Løbende ydelser',
  };

  // TODO: Change to key, when keys are set
  category2SettlementTypeMap: Record<string, string> = {
    '55025cff-351f-49e9-bacd-b6013843039f':
      'aa1bac48-5193-434a-859e-e4392a87f6f7', // Timekøb
    'e61ca465-35d0-4db9-b5de-4492c7d2da54':
      'f0a6c85f-3f1d-410f-8113-24cf71c2c605', // 'Løbende ydelser',
    '6f9fb37a-f47c-4d7f-a78b-94a3bcdaa201':
      '0e8f2643-2e41-4243-b7c7-6d2c4343e6db', // 'Fastpris',
  };

  async getInitialPushAnswers(_pas: AnswerSetBuilder): Promise<void> {
    const data = this.tender.data as TenderData0214;
    const questionnaireHelper = new QuestionnaireHelper();
    const deepQuestionnaire = await this.backend.questionnaireService.getQuestionnaire(
      this.tender.id,
      'specification',
    );
    if (data.solutionType === 'Category1') {
      const category1SettlementTypeQuestion = questionnaireHelper.locateQuestion(
        deepQuestionnaire.questions,
        '6eed7e38-14b5-4935-8395-7fd300d58a22',
      );
      // TODO: Change to key, when keys are set
      // const category1SettlementTypeQuestion = questionnaireHelper.locateQuestionByKey(deepQuestionnaire.questions, '');
      if (
        category1SettlementTypeQuestion?.enabled
        && category1SettlementTypeQuestion.questions
      ) {
        for (const option of category1SettlementTypeQuestion.questions) {
          const valueQuestion = option as ValueQuestionBase;
          const ddOptionId = this.category1SettlementTypeMap[valueQuestion.questionId];
          if (ddOptionId) {
            if (valueQuestion.value === 'true') {
              _pas.addCheck('990877cd-13a0-4a98-bb53-d8143fed0015', ddOptionId);
            } else {
              _pas.clearCheck('990877cd-13a0-4a98-bb53-d8143fed0015', ddOptionId);
            }
          }
        }
      }
    } else {
      const category2SettlementTypeQuestion = questionnaireHelper.locateQuestion(
        deepQuestionnaire.questions,
        'fd581022-5742-4a34-be11-38efae2185dd',
      );
      // TODO: Change to key, when keys are set
      // const category2SettlementTypeQuestion = questionnaireHelper.locateQuestionByKey(deepQuestionnaire.questions, '');
      if (
        category2SettlementTypeQuestion?.enabled
        && category2SettlementTypeQuestion.questions
      ) {
        for (const option of category2SettlementTypeQuestion.questions) {
          const valueQuestion = option as ValueQuestionBase;
          const ddOptionId = this.category2SettlementTypeMap[valueQuestion.questionId];
          if (ddOptionId) {
            if (valueQuestion.value === 'true') {
              _pas.addCheck('929f1093-b51c-477d-964c-cac2e94f3979', ddOptionId);
            } else {
              _pas.clearCheck(
                '929f1093-b51c-477d-964c-cac2e94f3979',
                ddOptionId,
              );
            }
          }
        }
      }
    }
  }

  getValidator(questionnaire: DocumentDrafterQuestionnaire) {
    return new RequestQuestionnaireValidator(this.tender, questionnaire);
  }
}

export default function getProvider(tender: Tender) {
  return new RequestQuestionnaireProvider(tender);
}
