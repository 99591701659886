<template>
  <v-container fluid>
    <TenderMaterialIntroText v-if="!readonly" :tender>
      <template #info_start>
        <p>Når du bruger de digitale dokumenter. genbruges informationen på tværs af materialet. Dokumenterne er indbyrdes forbundne, så fx informationer fra kravspecifikationen overføres til leveringskontrakten og opfordring til tilbud.</p>
        <p>Dokumenterne gemmes automatisk undervejs, og dine informationer og rettelser bliver automatisk overført til alle relevante dokumenter.</p>
      </template>
      <template #info_steps>
        <p class="mb-1">Sådan gør du:</p>
        <ol>
          <li>Afhold evt. <strong>markedsdialog</strong>, inden du offentliggør dit indkøb. Du igangsætter en markedsdialog under fanen <strong>Kommunikation</strong>.</li>

          <li>Definér dit behov under <strong>kravspecifikation</strong>. Du bør altid udfylde Kravspecifikation før Leveringskontrakt, for at få det rigtige dataflow mellem dokumenterne.</li>
          <li>Færdiggør <strong>leveringskontrakt</strong> og <strong>opfordring til tilbud</strong> i én arbejdsgang</li>
          <li>Markér dokumenterne <strong>Færdig</strong> under <strong>Angiv status</strong> for at aktivere knappen <strong>Godkend og offentliggør materiale</strong></li>
          <li>Kontroller og offentliggør dit materiale ved at klikke på <strong>Godkend og offentliggør materiale</strong></li>
        </ol>
      </template>
      <template #info_end>
        <p><b>Bemærk:</b> Når du vælger <b>’Godkend og offentliggør materiale’</b>, offentliggør du <b>ikke</b> for leverandørerne. Du får i det efterfølgende trin mulighed for at kontrollere dit <strong>materiale</strong> inden endelig offentliggørelse.</p>
        <p>SKI trepartsaftale er et obligatorisk bilag, når du bruger aftalen.</p>
      </template>
    </TenderMaterialIntroText>
    <TenderMaterialTable
      :documentList
      :tender
      :uploadModel
      @setDocumentDoneStatus="setDocumentDoneStatus"
      @removeFile="removeFile"
      @edit="onEdit($event)"
      @preview="onPreview($event)"
      :showFileInputMenu="true"
    >
      <template #editRequestForOffer="{ activatorType }">
        <RequestForOfferDialog
          :activatorType
          :tender
        />
      </template>
      <template #specificationInfo>
        <SimpleInfoDialog title="Kravspecifikation">
          <p>
            Under kravspecifikation specificerer du dine behov og udfylder de informationer, der er relevante for dit indkøb.
          </p>
          <p>
            Kravspecifikationen gemmes automatisk undervejs og dine valg, rettelser og informationer bliver automatisk overført til alle relevante dokumenter og bilag.
          </p>
          <p>
            Klik på de tre prikker til højre for <b>'Angiv status'</b> for at se en forhåndsvisning og downloade kravspecifikationen.
          </p>
          <p>
            Hvis du har spørgsmål eller brug for rådgivning, er du velkommen til at kontakte os.
          </p>
        </SimpleInfoDialog>
      </template>
      <template #requestForOfferInfo>
        <SimpleInfoDialog title="Leveringskontrakt og opfordring til tilbud">
          <p>
            Færdiggør leveringskontrakten og udfyld opfordring til tilbud. Du bliver guidet til at udfylde begge dokumenter i én arbejdsgang.
          </p>
          <p>
            Leveringskontrakt og opfordring til tilbud gemmes automatisk undervejs og dine valg, rettelser og informationer bliver automatisk overført til alle relevante dokumenter.
          </p>
          <p>
            Du kan løbende se og skifte mellem dokumenterne i <b>'Preview'</b>-funktionen øverst i systemet.
          </p>
          <p>
            Når du er færdig med dokumenterne, ændrer du status til <b>'Færdig'</b> og aktiverer knappen <b>'Godkend og offentliggør materiale'</b>.
          </p>
          <p>
            Klik på de tre prikker til højre for <b>'Angiv status'</b> for at se og downloade bilagene enkeltvis eller samlet.<br />
            Hvis du har spørgsmål eller brug for rådgivning, er du velkommen til at kontakte os.
          </p>
        </SimpleInfoDialog>
      </template>
    </TenderMaterialTable>
    <v-row v-if="!readonly" no-gutters>
      <v-col style="max-width: 350px !important" class="text-center">
        <img
          alt=""
          class="ml-1"
          height="40"
          src="@/assets/arrow_green_down.svg"
        />
        <div class="ml-4">
          <PublishTenderDialog
            :tender
            :disabled="!mixin.publishTenderEnabled()"
            @updateTab="updateTab"
          />
          <TenderMaterialAfterText :publishTenderEnabled="mixin.publishTenderEnabled()" :isTemplate />
        </div>
      </v-col>
    </v-row>
    <SpecificationEditDialog
      v-model="showSpecificationEditor"
      :tender
    >
      <p>Her udarbejder du din behovsopgørelse. Den skal indeholde dine krav til indkøbet og oplysninger, der er nødvendige for et sammenhængende udbudsmateriale.</p>
      <p>Behovsopgørelsen indgår sammen med opfordringsskrivelsen og leveringskontrakten i det udbudsmateriale, du skal offentliggøre til potentielle leverandører for, at de kan afgive tilbud.</p>
      <p>Du bliver i det følgende præsenteret for de spørgsmål, der er relevante for indkøbet.</p>
      <p>Du vil løbende blive guidet til at udfylde felter. Dine svar vil blive gengivet i de relevante bilag, som behovsopgørelsen består af samt evt. øvrige relevante udbudsdokumenter.</p>
      <p>Du vil løbende i vejledninger kunne se, hvor i materialet dine svar kommer til at fremgå.</p>
      <h2>Hjælpefunktioner</h2>
      <p>Øverst i billedet har du mulighed for at vælge 'Vis som leverandør'. 'Preview', 'Hjælp', og 'Hent noter',</p>
      <p>Brug 'Vis som leverandør' til løbende at se tilbudslisten, som den vil blive præsenteret for leverandøren.</p>
      <p>I 'Preview'-funktionen kan du løbende se og skifte mellem de bilag dine svar er angivet i. Du kan også downloade bilagene enkeltvist eller samlet.</p>
      <p>Under 'Hjælp', får du et hurtigt overblik over væsentligste funktionaliteter i systemet.</p>
      <p>Ved at klikke på 'Hent noter' får du en PDF med alle de interne noter, du evt. har tilføjet.</p>
    </SpecificationEditDialog>
    <SpecificationPreviewDialogWithoutActivator
      v-model="showSpecificationViewer"
      :tender
    />
    <LoadingSpinner :visible="isLoading" />
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import {
  LoadingSpinner,
  MaterialDocument,
  MaterialFileInfo,
  useStore,
  TenderMaterialTable,
  SimpleInfoDialog,
  PublishTenderDialog,
  RequestForOfferDialog,
  SpecificationPreviewDialogWithoutActivator,
  TenderMaterialIntroText,
  TenderMaterialAfterText,
  SpecificationEditDialog,
  TenderStateEnum,
  TenderMaterialMixin,
  Tender,
} from '@dims/components';

/** Presentations of documents when tender is in draft state.
 * Each of the standard files can be replaced by a custom uploaded file
 * Additional custom files can be uploaded
 * For the standard documents, link to questionnaire dialog
 * For custom documents, download.
 */
const emit = defineEmits<{
  isLoaded: [true],
  updateTab: [string],
}>();
const { tender, readonly = false } = defineProps<{
  readonly?: boolean,
  tender: Tender,
}>();
const store = useStore();
const showSpecificationEditor = ref(false);
const showSpecificationViewer = ref(false);
const isLoading = ref(false);

const mixin = ref<TenderMaterialMixin>(new TenderMaterialMixin(tender, false, false, false, true));

onMounted(() => {
  emit('isLoaded', true);
});

watch(
  () => tender,
  (newTender: Tender) => {
    mixin.value.tender = newTender;
  },
);

const documentList = computed(() => mixin.value.getDocumentLines());

async function removeFile(file: MaterialFileInfo) {
  isLoading.value = true;
  try {
    await mixin.value.removeFile(file);
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Kan ikke fjerne fil.');
  } finally {
    isLoading.value = false;
  }
}

function onEdit(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationEditor.value = true;
  }
}

function onPreview(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationViewer.value = true;
  }
}

function updateTab(tabName: string) {
  emit('updateTab', tabName);
}

async function setDocumentDoneStatus(doc: MaterialDocument, completed: boolean) {
  isLoading.value = true;
  try {
    await mixin.value.setDocumentDoneStatus(doc, completed);
  } catch (e) {
    console.error('Could not update tender', e);
    store.setSnackbarText('Kan ikke markere dokument som færdigt.');
  } finally {
    isLoading.value = false;
  }
}

const isTemplate = computed(() => tender.state === TenderStateEnum.Template);

const uploadModel = computed(() => mixin.value.getUploadModel());

</script>
